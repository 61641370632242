@use "../../scss/" as *;


.hero {
  margin-top: 40px;
  @include flex(center, space-between);
  @include tablet{
    margin-top: 100px;
  }
  @include mobile{
    position: relative;
    padding: 0 30px;
    text-align: center;
    margin-top: 0px;
  }
     @include mobilssm {
       flex-flow: column-reverse;
     }
  @include mobilssm2{
    padding: 0 10px;
    margin-top: 70px;
    flex-flow: column-reverse ;
  }

 
 

  // @include mobilssm{
  //   margin-top: 200px;
  // }

 
  &__content {
    flex: 1;
    @include flex(flex-start, center);
    flex-flow: column;
    height: 100%;

    @include mobile{
      @include flex(center, center);
    }

    &__info {
      padding-right: 20px;

     

      .title {
        font-size: 3rem;
        color: $white;
        font-weight: bold;

        span{
          position: relative;
          &::before{
            content: '';
            height: 3px;
            width: 100%;
            background-color: $main-color;
            position: absolute;
            bottom: 0;
          }
        }

        @include tablet {
          font-size: 2rem;
        }
        @include mobile{
          font-size: 1.6rem;
        }
        @include mobilssm2{
          font-size: 1.5rem;
        }
      }

      .description {
        margin-top: 15px;
        color: $white;
        span {
          font-size: 2rem;
          @include tablet{
            font-size: 1.5rem;
          }
          
        }
        p {
          font-size: 17px;
          margin-top: 15px;
          line-height: 1.7;
          @include tablet{
            font-size: 13px;
          }
        }
      }
      
      @include mobile {
        width: 100%;

        .description {
          span {
            font-size: 1.5rem;
          }
          p {
            font-size: 11px;
          }
        }
      }
    }

    @include mobilssm2{
      .description{
        span {
            font-size: 1.5rem;
          }
          p{
            font-size: 12px;
          }
      }
    }

    .price {
      margin-top: 10px;
      font-size: 1.7rem;
      color: rgb(0, 199, 0);
      font-weight: bold;
      span {
        color: red;
        text-decoration: line-through;
        margin-right: 10px;
        font-weight: normal;
        font-size: 1.2rem;
      }

      @include mobile {
        font-size: 2rem;
        span{
          font-size: 1.5rem;
        }
      }
    }
  }

  .inka-img {
   flex: 1;
   @include flex(center, center);
  img {
      width: 400px;
      height: auto;
      margin-top: 90px;
  
  
  
      @include tablet {
        width: 300px;
        margin-top: 10px;
      }
      
      @include mobile{
        width: 200px;
        margin-top: 0;
        
      }
      @include mobilssm{
        width: 120px;
        margin-top: -60px;
        margin-right: 50px;
      }
      @include mobilssm2{
        width: 100px;
        margin-top: 5px;
        margin-bottom: 20px;
      }
    }
    
   

    
  }
}
