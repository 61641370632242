$main-color: #ed8037;
$txt-color : #000;
$green-color : #04cd04;
$white : #fff;

$box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

$header-height: 8rem;
$header-shrink-height: 5rem;
$border-radius: 10px;

$mobile-width: 769px;
$mobile-500: 537px;
$mobile-320: 400px;
$sm-270: 270px;
$tablet-width: 1150px;

$font-family: "Nunito", Sans-serif;