@use '../../scss/' as *;

.countDown{
    margin-right: 150px;
    @include flex(center, center);
    gap: 10px;

    @include mobile{
        margin-right: 90px;
        margin-top: 60px;
    }

    @include mobilssm2{
        margin-right: 85px;
        margin-top: 370px;   
    }

}




