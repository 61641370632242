@use "./scss/" as *;

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;500;600;700;800&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html{
  font-size: 100%;

  @include tablet{
    font-size: 80%;
  }

  @include mobile{
    font-size: 60%;
  }

}

body{
  font-family: $font-family;
  font-weight: 400;
  line-height: 1.5;

  color: $txt-color;

  @include mobilssm{
    margin-bottom: 3rem;
    background-image: linear-gradient(rgba(5, 7, 5, 0.8), rgba(5, 7, 5, 0.8)), url("./assets/bg-mobille-400-2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
      min-height: 100vh;
      padding-bottom: 250px;
  }

}

a{
  text-decoration: none;
  color: unset;
}

@media (hover: hover){
  a:hover{
    color: $main-color;
  }
}

button, input{
  outline: 0;
}

img{
  max-width: 100%;
}

.container{
  max-width: 1400px;
  margin: auto;
  padding: 0 50px;
}

.mb-3{
  margin-bottom: 3rem;
}

.mb-2{
  margin-bottom: 2rem;
}

.section{
  padding: 0 2rem;
}

.section__header{
  @include flex(start, center)
}

