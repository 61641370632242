@use '../../scss/' as * ;


.count-btn{
    background: $main-color;
        color: #fff;
        font-size: 22px;
        display: flex;
        padding: 12px 25px;
        @include flex(center, center);
        gap: 7px;
        position: relative;
        overflow: hidden;
        font-weight: bold;
        font-size: 18px;
        border-radius: 5px;
        cursor: pointer;
        span{
            font-size: 15px;
        }


      

        @include tablet{
            padding: 9px 16px;
            font-size: 12px;
        }

        @include mobile{
            padding: 8px 15px;
            font-size: 14px;
            span{
                font-size: 10px;
            }
        }

        @include mobilssm{
            gap: 2px;
                padding: 9px 13px;
                font-size: 12px;
            
                span {
                    font-size: 9px;
                    font-weight: normal;
                }
        }

        @include mobilssm2{
            gap: 2px;
            padding: 6px 9px;
            font-size: 10px;
            span{
                font-size: 7px;
                font-weight: normal;
            }
        }

}
