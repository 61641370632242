@use './variables' as * ;

@mixin mobile {
    @media only screen and (max-width: $mobile-width) {
        @content;
    }
}

@mixin mobilssm{
    @media only screen and (max-width: $mobile-500){
        @content;
    }
}
@mixin mobilssm2{
    @media only screen and (max-width: $mobile-320){
        @content;
    }
}

@mixin sm270 {
    @media only screen and (max-width: $sm-270){
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: $tablet-width) {
        @content;
    }
}





