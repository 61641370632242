@use '../../scss/' as * ;

.home{
    height: 100vh;
    background-image: linear-gradient(rgba(7,10,7,0.7), rgba(7, 10, 7, 0.7)), url("../../assets/Mme\ Arielle.jpg");
    background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include flex(flex-start, center);
        flex-flow: column;
        padding: 0 70px;
        @include mobile{
            background: linear-gradient(rgba(5, 7, 5, 0.8), rgba(5, 7, 5, 0.8)), url("../../assets/Mme\ Arielle.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            padding: 0;
            background-position: top;
            min-height: 100vh;
            @include flex(center, center);
        }


        @include mobilssm2{
            background: none;
            background-position-y: -20%;
            padding: 0 10px;
            gap: 30px;
        }
        
}



