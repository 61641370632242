@use "../../scss/" as *;

@keyframes appear {
  from {
    margin-top: -100px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

.buttons {
  margin-top: -50px;
  @include flex(center, space-between);
  gap: 20px;
  @include tablet{
    margin-top: 10px;
  }
  @include mobile{
    margin-top: 10px;
    flex-wrap: wrap;
    @include flex(center, center);
  }
  @include mobilssm2{
    padding-bottom: 40px;
  }

  .discover-btn {
    background: linear-gradient(60deg, #00c700, #22f722);
    color: #fff;
    font-size: 20px;
    display: flex;
    @include flex(center, center);
    gap: 7px;
    transition: hover 0.3s ease;
    animation: appear 2s ease;

    &:hover {
      background: none;
      border: 2px solid #00c700;
      color: #00c700;
      font-weight: bold;
    }

    @include mobile{
      font-size: 13px;
    }

         @include mobilssm2 {
           font-size: 15px;
         }

  }

  .pay-btn {
    background: linear-gradient(60deg, #cf3d02, #e8a714);
    color: #fff;
    font-size: 20px;
    display: flex;
    @include flex(center, center);
    gap: 7px;
    transition: hover 0.3s ease;
    animation: appear 1.5s ease;

    &:hover {
      background: none;
      border: 2px solid #cf3d02;
      color: #cf3d02;
      font-weight: bold;
    }

        @include mobile {
          font-size: 13px;
        }

    @include mobilssm2{
      font-size: 15px;
    }
  }

  .amazone-btn {
    .am-black {
      display: none;
    }
    img {
      height: 25px;
    }
    background: linear-gradient(60deg, #221f1f, #3a3939);
    padding: 11px 22px 7px 22px;
    animation: appear 1.5s ease;

    &:hover {
      
      background: none;
      color: #221f1f;
      border: 1px solid $white;
    }

        @include mobile {
          img{
            height: 16px;
          }
        }

        @include mobilssm{
          padding: 9px 16px 7px 16px;
          img{
            height: 14px;
          }
        }

    @include mobilssm2{
      img{
        height: 15px;
      }
    }
  }
}
