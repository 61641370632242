@use "../../scss/" as *;

.header {
  height: $header-height;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  @include mobile{
    position: static;
    margin-bottom: 90px;
    @include flex(center, center);
  }
  @include mobilssm{
    margin-top: 140px;
  }

  &__wrapper {
    @include flex(center, space-between);
    position: relative;

    .logo {
      align-self: flex-start;
      @include flex(center, center);
      margin-top: 20px;
      height: 80px;
      width: 80px;
      padding: 20px;
      border-radius: 50%;
      background-color: $white;
      img {
        width: 50px;
      }
      margin-right: 10px;
      
    }

    @include mobile{
      @include flex(center, center);
      padding-left: 80px;
      
      .logo{
        height: 80px;
        width: 80px;
        margin-right: 20px;
          margin-top: 60px;
          flex: none;
      }
    }

    @include mobilssm{
      .logo {
          height: 60px;
          width: 60px;
          margin-right: 20px;
          margin-top: 60px;
          flex: none;
        }
    }

        @include mobilssm2 {
          .logo{
            position: absolute;
              top: 60%;
              left: 50%;
              transform: translateX(-50%);
              img{
                width: 70px;
              }
          }
          
        }

    
  }
}
